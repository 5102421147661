import { emit, qwebApi } from '@/apis/qwebApi'
import store from '@/store'
import stats, { TABLE_TYPE, STATS_POINT } from '@/tool/stats'
// import { getTrackId } from '@/tool/track'

// Game Type Mapping
const gameTypeMap = {
  simulator: 'Android_Game',
  local: 'Android_Game',
  cloud: 'Cloud_Game',
  steam: 'Steam_Game',
}

// Event type for stat
const getEventType = (gameType, isInstalled) => {
  if (gameType === 'simulator') {
    if (isInstalled) {
      return 'play_on_android'
    } else {
      return 'install_on_android'
    }
  }
  if (gameType === 'cloud') {
    return 'play_on_cloud'
  }
  if (gameType === 'steam') {
    return 'play_on_steam'
  }
}

// Record stat data
export const getAppClickStats = ({ app, bannerLocation, isInstalled, gameType }) => {
  // console.log('=================', getTrackId(), '=================')
  return {
    // trackId: getTrackId(),
    app_pkg: app.package_name,
    banner_location: bannerLocation,
    event_type: getEventType(gameType, isInstalled),
    game_type: gameTypeMap[gameType],
  }
}

// On game clicked
export const onGameClickAction = async ({
  app,
  isInstalled,
  evt,
  gameType = 'simulator',
  bannerLocation,
  actionCode,
  redirectCode,
}) => {
  evt.preventDefault()
  evt.stopPropagation()
  // Record data
  const statsData = getAppClickStats({ app, isInstalled, bannerLocation, gameType })

  const isAppDetailAccessable =
    app.isAppDetailAccessable && store.getState().system.countryCode !== 'JP'

  // Required infomation for launch app
  const gamePlayInfo = {
    ...app,
    icon_url: app.icon_url,
    package_name: app.package_name,
    cloud_app_url: app.cloud_app_url,
    cloud_supported: app.cloud_supported,
    category: app.category,
    game_name: app.game_name,
    action: app.action,
    action_value: app.action_value,
    apk_url: app.apk_url,
    is_nowgg_login_required: app.is_nowgg_login_required,
    game_play_preference: app.game_play_preference,
    isAppDetailAccessable,
    redirectCode,
  }
  // Jump to /googleSearch(if it's google play app)
  // if (redirectCode === 'AppGoogleSearch' || app.package_name === GOOGLE_PLAY_PCK) {
  if (redirectCode === 'AppGoogleSearch') {
    emit('onRoute', {
      action: 'push',
      pathname: '/googleSearch',
      state: app,
    })
    stats(TABLE_TYPE.LAUNCHER_APP_CLICK_V2, {
      ...gamePlayInfo,
      ...statsData,
      actionCode,
      event_type: STATS_POINT.BSX_GPSTORE_CLICKED,
    })
    return
  }
  // Judge to App Detail page
  if (redirectCode === 'AppDetail') {
    emit('openDetailPage', { ...app, $$isClear: false, $$from: statsData.banner_location })
    stats(TABLE_TYPE.LAUNCHER_APP_CLICK, {
      ...gamePlayInfo,
      ...statsData,
      actionCode,
      event_type: STATS_POINT.BSX_JUMP_TO_DETAIL_PAGE,
    })
    return
  }
  // gameType:simulator ,local:Android Game,cloud:Cloud Game,steam PC Game
  if (gameType === 'cloud') {
    qwebApi({
      event: 'openCloudGame',
      data: gamePlayInfo,
      ga: statsData,
    })
  } else if (gameType === 'steam') {
    qwebApi({
      event: 'openStreamGame',
      data: gamePlayInfo,
      ga: statsData,
    })
  } else if (gameType === 'simulator' || gameType === 'local') {
    qwebApi({
      event: 'installOrPlayAppforVm',
      data: gamePlayInfo,
      ga: statsData,
    })
  } else {
    console.warn("Don't match action,please check your action params")
  }
}

// Install or open pc game
export const installOrOpenPCGame = ({ location, url, packageName, isInstalled }) => {
  // const installedPcGames = store.getState().app.installedPcGames || []
  // const isInstalled = installedPcGames.includes(packageName)
  if (isInstalled) {
    qwebApi({
      event: 'launcherPCGame',
      data: {
        banner_location: location,
        packageName,
      },
    })
  } else {
    qwebApi({
      event: 'installPCGame',
      data: {
        banner_location: location,
        packageName,
        url,
      },
    })
  }
}
